<script setup>
const { mdAndUp, lgAndUp, smAndDown } = useDisplay();
import { useDisplay } from "vuetify";
import {  Link } from "@inertiajs/vue3";

</script>
<style>
.v-responsive__content {
    display: flex;
    align-items: center;
}
</style>
<style scoped>
:deep(.v-btn--active){
    color: rgb(45, 45, 45) !important;
}
:deep(.v-carousel__controls){
    color: rgb(152, 152, 152) !important;
}

h2{
    line-height: 65px!important;
}
@media (max-width: 850px) {
    h2{
    line-height: 45px!important;
}
}
.v-responsive__content {
    display: flex;
    align-items: center;
}

:deep(.v-responsive__content) {
    display: flex;
    align-items: center;
}

:deep(.v-btn.v-btn--density-default) {
    width: calc(var(--v-btn-height) - 8px) !important;
    height: calc(var(--v-btn-height) - 8px) !important;
}

:deep(.v-btn--icon.v-btn--density-default) {
    width: calc(var(--v-btn-height) - 8px) !important;
    height: calc(var(--v-btn-height) - 8px) !important;
}

.v-btn--icon.v-btn--density-default {
    width: calc(var(--v-btn-height) - 8px) !important;
    height: calc(var(--v-btn-height) - 8px) !important;
}
</style>
<template>
    <!-- #ffd2b3fa -->
    <!-- #fff4eb -->


    <div class=" bg-[#fff4eb] rounded-xl  relative" data-aos="fade-up">
        <div
            class="h-full w-full bg-[url('/assets/home/texture.png')] bg-no-repeat bg-center bg-origin-padding bg-cover rounded-xl">
            <div
                class="h-full w-full min-h-60  my-auto backdrop-blur-[18px] bg-base/9 0 rounded-xl border-[1px] border-solid border-[#00000010] ">
                <div class=" relative">
                    <img preload alt="3kode" src="/assets/b.svg"
                        class="absolute bottom-0  rtl:right-0 ltr:left-0  transform transition-all w-1/3  duration-700 opacity-5 "></img>
                    <img preload alt="3kode" src="/assets/home/bg.png"
                        class="absolute top-0 rtl:left-0  ltr:right-0 ltr:-scale-x-100 rounded-2xl transform transition-all duration-700 opacity-5 "
                        v-if="mdAndUp"></img>
                    <img preload alt="3kode" src="/assets/home/text.png"
                        class="absolute top-3 rtl:right-0 ltr:left-0 w-60 transform transition-all  duration-700 opacity-5 "
                        v-if="mdAndUp"></img>
                    <!-- cycle -->
                    <v-carousel :show-arrows="mdAndUp ? 'hover' : true" cycle :continuous="true"
                        hide-delimiter-background :height="mdAndUp ? 510 : 750">
                        <v-carousel-item>
                            <v-row class="flex ">
                                <v-col cols="12" md="5" class="flex justify-center items-center py-0 px-6 "
                                    v-if="!mdAndUp">
                                    <img preload alt="3kode" src="/assets/home/robot.svg"
                                        class=" transform transition-all duration-700 max-h-[300px]  w-full "></img>
                                </v-col>
                                <v-col cols="12" md="7" class="flex justify-center items-center">
                                    <div
                                        class="flex flex-col px-12 px-md-14 text-center text-md-start ltr:md:lg-12 rtl:lg:mr-12">

                                        <img preload alt="3kode" src="/assets/logo/konnectly.svg"
                                            style="filter: invert(0.0) sepia(0) saturate(0);" v-if="mdAndUp"
                                            class="mb-2 transform transition-all w-[130px]  duration-700 opacity-80 "></img>
                                        <p class="text-xl leading-10 " data-aos="fade-up">
                                            {{ __t('automations') }} <span v-if="mdAndUp">Automation</span>
                                        </p>
                                        <h2 class="xl:text-5xl text-3xl font-bold " style="line-height: normal;"
                                            v-html="__t('automationSolutions')">


                                        </h2>
                                        <p class="text-lg leading-10 mt-3" data-aos="fade-up">
                                            {{ __t('automationsDes') }}

                                        </p>
                                        <div class="flex justify-center justify-md-start">
                                            <Link :href="route('automations')">

                                                <v-btn :aria-label="__t('Header.menu')" color="primarydark" rounded="lg"
                                                    class="w-[220px] mt-5 mb-8 mb-md-0" density="comfortable">
                                                    <div class=" text-[16px] w-full text-white">
                                                        {{ __t('moreInfo') }}
                                                    </div>
                                                </v-btn>
                                            </Link>

                                        </div>

                                    </div>
                                </v-col>
                                <v-col cols="12" md="5" class="flex justify-start items-center" v-if="mdAndUp">
                                    <img preload alt="3kode" src="/assets/home/robot.svg"
                                        class=" transform transition-all duration-700 max-h-[500px] "></img>
                                </v-col>
                            </v-row>
                        </v-carousel-item>

                        <v-carousel-item>
                            <v-row class="flex ">
                                <v-col cols="12" md="5" class="flex justify-center items-center py-0 px-6 "
                                    v-if="!mdAndUp">
                                    <img preload alt="3kode" src="/assets/home/crm2.svg"
                                        class=" transform transition-all duration-700 max-h-[300px] w-full"></img>
                                </v-col>
                                <v-col cols="12" md="7" class="flex justify-center items-center">
                                    <div
                                        class="flex flex-col px-12 px-md-14 text-center text-md-start ltr:lg:ml-12 rtl:lg:mr-12">

                                        <img preload alt="3kode" src="/assets/logo/konnectly.svg"
                                            style="filter: invert(0.0) sepia(0) saturate(0);" v-if="mdAndUp"
                                            class="mb-2 transform transition-all w-[130px]  duration-700 opacity-80 "></img>
                                        <p class="text-xl leading-10 " data-aos="fade-up">
                                            {{ __t('crm') }} <span>CRM</span>
                                        </p>
                                        <h2 class="xl:text-5xl text-3xl font-bold " style="line-height: normal;"
                                            v-html="__t('crmSolutions')">


                                        </h2>
                                        <p class="text-lg leading-10 mt-3" data-aos="fade-up">
                                            {{ __t('crmDes') }}

                                        </p>
                                        <div class="flex justify-center justify-md-start">
                                            <Link  :href="route('konnectly')">

                                                <v-btn :aria-label="__t('Header.menu')" color="primarydark" rounded="lg"
                                                    class="w-[220px] mt-5 mb-8 mb-md-0" density="comfortable">
                                                    <div class=" text-[16px] w-full text-white">
                                                        {{ __t('moreInfo') }}
                                                    </div>
                                                </v-btn>
                                            </Link>

                                        </div>

                                    </div>
                                </v-col>
                                <v-col cols="12" md="5" class="flex justify-start items-center" v-if="mdAndUp">
                                    <img preload alt="3kode" src="/assets/home/crm2.svg"
                                        class=" transform transition-all duration-700 max-h-[500px] "></img>
                                </v-col>
                            </v-row>
                        </v-carousel-item>
                        <v-carousel-item>
                            <v-row class="flex ">
                                <v-col cols="12" md="5" class="flex justify-center items-center py-0 px-6  "
                                    v-if="!mdAndUp">
                                    <img preload alt="3kode" src="/assets/home/learn.svg"
                                        class=" transform transition-all duration-700 max-h-[300px] w-full"></img>
                                </v-col>
                                <v-col cols="12" md="7" class="flex justify-center items-center">
                                    <div
                                        class="flex flex-col px-12 px-md-14 text-center text-md-start ltr:lg:ml-12 rtl:lg:mr-12">

                                        <img preload alt="3kode" src="/assets/logo/ashara-logo.png"
                                            style="filter: invert(0.0) sepia(0) saturate(0);" v-if="mdAndUp"
                                            class="mb-2 transform transition-all w-[80px]   duration-700 opacity-80 "></img>
                                        <p class="text-xl leading-10 " data-aos="fade-up">
                                            {{ __t('lms') }} <span>LMS</span>
                                        </p>
                                        <h2 class="xl:text-5xl text-3xl font-bold " style="line-height: normal;"
                                            v-html="__t('lmsSolutions')">


                                        </h2>
                                        <p class="text-lg leading-10 mt-3" data-aos="fade-up">
                                            {{ __t('lmsDes') }}
                                        </p>
                                        <div class="flex justify-center justify-md-start">
                                            <Link :href="route('ashara')">

                                                <v-btn :aria-label="__t('Header.menu')" color="primarydark" rounded="lg"
                                                    class="w-[220px] mt-5 mb-8 mb-md-0" density="comfortable">
                                                    <div class=" text-[16px] w-full text-white">
                                                        {{ __t('moreInfo') }}
                                                    </div>
                                                </v-btn>
                                            </Link>

                                        </div>

                                    </div>
                                </v-col>
                                <v-col cols="12" md="5" class="flex justify-start items-center" v-if="mdAndUp">
                                    <img preload alt="3kode" src="/assets/home/learn.svg"
                                        class=" transform transition-all duration-700 max-h-[500px] py-3"></img>
                                </v-col>
                            </v-row>
                        </v-carousel-item>

                        <v-carousel-item>
                            <v-row class="flex  items-center">
                                <v-col cols="12" md="5" class="flex justify-center items-center py-0 px-6  "
                                    v-if="!mdAndUp">
                                    <img preload alt="3kode" src="/assets/home/team.svg"
                                        class=" transform transition-all duration-700 max-h-[300px]  w-full "></img>
                                </v-col>
                                <v-col cols="12" md="7" class="flex justify-center items-center">
                                    <div
                                        class="flex flex-col px-12 px-md-14 text-center text-md-start ltr:lg:ml-12 rtl:lg:mr-12">

                                        <img preload alt="3kode" src="/assets/3kode.svg"
                                            style="filter: invert(0.0) sepia(0) saturate(0);" v-if="mdAndUp"
                                            class="mb-2 transform transition-all w-[130px]  duration-700 opacity-80 "></img>
                                        <p class="text-xl leading-10 " data-aos="fade-up">
                                            {{ __t('software') }}
                                        </p>
                                        <h2 class="xl:text-5xl text-3xl font-bold " style="line-height: normal;"
                                            v-html="__t('advancedSofwareSolutions')">


                                        </h2>
                                        <p class="text-lg leading-10 mt-3" data-aos="fade-up">
                                            {{ __t('softwareDes') }}
                                        </p>
                                        <div class="flex justify-center justify-md-start">
                                            <a href="#contact">

                                                <v-btn :aria-label="__t('Header.menu')" color="primarydark" rounded="lg"
                                                    class="w-[220px] mt-5 mb-8 mb-md-0" density="comfortable">
                                                    <div class=" text-[16px] w-full text-white">
                                                        {{ __t('conclusion') }}
                                                    </div>
                                                </v-btn>
                                            </a>

                                        </div>

                                    </div>
                                </v-col>
                                <v-col cols="12" md="5" class="flex justify-start items-center" v-if="mdAndUp">
                                    <img preload alt="3kode" src="/assets/home/team.svg"
                                        class=" transform transition-all duration-700 max-h-[500px] "></img>
                                </v-col>
                            </v-row>
                        </v-carousel-item>

                        <v-carousel-item>
                            <v-row class="flex ">
                                <v-col cols="12" md="5" class="flex justify-center items-center py-0 px-6  "
                                    v-if="!mdAndUp">
                                    <img preload alt="3kode" src="/assets/home/bitcoin.svg"
                                        class=" transform transition-all duration-700 max-h-[300px]  w-full "></img>
                                </v-col>
                                <v-col cols="12" md="7" class="flex justify-center items-center">
                                    <div
                                        class="flex flex-col px-12 px-md-14 text-center text-md-start ltr:lg:ml-12 rtl:lg:mr-12">

                                        <img preload alt="3kode" src="/assets/logo/3coin.svg"
                                            style="filter: invert(0.0) sepia(0) saturate(0);" v-if="mdAndUp"
                                            class="mb-2 transform transition-all w-[60px]  duration-700 opacity-80 "></img>
                                        <p class="text-xl leading-10 " data-aos="fade-up">
                                            {{ __t('cryptocurrency') }}

                                        </p>
                                        <h2 class="xl:text-5xl text-3xl font-bold " style="line-height: normal;"
                                            v-html="__t('cryptocurrencySolutions')">

                                        </h2>

                                        <p class="text-lg leading-10 mt-3" data-aos="fade-up">
                                            {{ __t('cryptocurrencyDes') }}
                                        </p>
                                        <div class="flex justify-center justify-md-start">
                                            <Link  :href="route('threecoin')">

                                            <v-btn :aria-label="__t('Header.menu')" color="primarydark" rounded="lg"
                                                class="w-[220px] mt-5 mb-8 mb-md-0" density="comfortable">
                                                <div class=" text-[16px] w-full text-white  ">
                                                    {{ __t('moreInfo') }}
                                                </div>
                                            </v-btn>
                                        </Link>
                                        </div>

                                    </div>
                                </v-col>
                                <v-col cols="12" md="5" class="flex justify-start items-center" v-if="mdAndUp">
                                    <img preload alt="3kode" src="/assets/home/bitcoin.svg"
                                        class=" transform transition-all duration-700 max-h-[500px] "></img>
                                </v-col>
                            </v-row>
                        </v-carousel-item>

                    </v-carousel>
                </div>
            </div>
        </div>
    </div>

</template>